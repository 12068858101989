<template>
	<div class="m23_1 wrapper">
		<div class="top-nav">
			<div class="icon-txt">
				<a @click="$router.go(-1)">
					<div class="top-iconBack">
						<img :src="require('@/assets/img/ic_back.svg')" alt="뒤로가기" />
					</div>
				</a>
				<p>법인회원 가입안내</p>
			</div>
		</div>
		<!-- 가입내역 소개 글 -->
		<div class="join-info top-4">
			<div class="did-info">
				<p>법인용 우체국 인증서 서비스 소개</p>
				<span
					>법인용 우체국 인증서란?<br />모든 우정서비스를 이용할 수 있는 우체국
					인증서 서비스</span
				>
			</div>
			<!-- //가입내역 소개 글 -->
			<!-- 앱/웹 아이콘 -->
			<div class="info-icon">
				<div class="app">
					<img :src="require('@/assets/img/phone_iphone.png')" alt="app" />
					App
				</div>
				<div class="web">
					<img :src="require('@/assets/img/desktop_windows.png')" alt="wep" />
					Web
				</div>
			</div>
			<!-- //앱/웹 아이콘 -->
			<!-- step 1~6 -->
			<div class="info-step">
				<ul>
					<!-- step loof -->
					<li class="roundbox-txt">
						<div class="step-icon">
							<img :src="require('@/assets/img/step_1.png')" alt="step1" />
						</div>
						<div class="step-txt">
							<span>Step <i>01</i></span>
							<p>우체국 앱 회원가입</p>
							<em>(업체 대표자, 대리인)</em>
						</div>
						<div class="info-icon">
							<div class="app step">
								<img
									:src="require('@/assets/img/phone_iphone.png')"
									alt="app"
								/>
							</div>
						</div>
					</li>
					<svg
						version="1.1"
						id="레이어_1"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						x="0px"
						y="0px"
						viewBox="0 0 100 100"
						style="enable-background: new 0 0 100 100"
						xml:space="preserve"
					>
						<path
							class="st0"
							d="M69.1,35.8L50,54.9L30.9,35.8L25,41.7l25,25l25-25L69.1,35.8z"
						/>
					</svg>
					<!-- //step loof -->
					<!-- step loof -->
					<li class="roundbox-txt">
						<div class="step-icon">
							<img :src="require('@/assets/img/step_2.png')" alt="step1" />
						</div>
						<div class="step-txt">
							<span>Step <i>02</i></span>
							<p>
								<a href="#" class="link"
									>우체국 인증포털
									<img :src="require('@/assets/img/ic_page_open.png')" alt=""
								/></a>
							</p>
							<!-- <a href="#" class="btn_link">바로가기</a> -->
						</div>
						<div class="info-icon">
							<div class="web step">
								<img
									:src="require('@/assets/img/desktop_windows.png')"
									alt="app"
								/>
							</div>
						</div>
					</li>
					<svg
						version="1.1"
						id="레이어_1"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						x="0px"
						y="0px"
						viewBox="0 0 100 100"
						style="enable-background: new 0 0 100 100"
						xml:space="preserve"
					>
						<path
							class="st0"
							d="M69.1,35.8L50,54.9L30.9,35.8L25,41.7l25,25l25-25L69.1,35.8z"
						/>
					</svg>
					<!-- //step loof -->
					<!-- step loof -->
					<li class="roundbox-txt">
						<div class="step-icon">
							<img :src="require('@/assets/img/step_3.png')" alt="step1" />
						</div>
						<div class="step-txt">
							<span>Step <i>03</i></span>
							<p>법인용 우체국 인증서 발급 신청</p>
							<em>(약관 동의 및 사업자, 신청자정보 입력 후 완료)</em>
						</div>
						<div class="info-icon">
							<div class="web step">
								<img
									:src="require('@/assets/img/desktop_windows.png')"
									alt="app"
								/>
							</div>
						</div>
					</li>
					<svg
						version="1.1"
						id="레이어_1"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						x="0px"
						y="0px"
						viewBox="0 0 100 100"
						style="enable-background: new 0 0 100 100"
						xml:space="preserve"
					>
						<path
							class="st0"
							d="M69.1,35.8L50,54.9L30.9,35.8L25,41.7l25,25l25-25L69.1,35.8z"
						/>
					</svg>
					<!-- //step loof -->
					<!-- step loof -->
					<li class="roundbox-txt">
						<div class="step-icon">
							<img :src="require('@/assets/img/step_4.png')" alt="step1" />
						</div>
						<div class="step-txt">
							<span>Step <i>04</i></span>
							<p>법인용 우체국 인증서 신청 심사</p>
							<em>(법인용 우체국 인증서 관리자 심사)</em>
						</div>
						<div class="info-icon">
							<div class="web step">
								<img
									:src="require('@/assets/img/desktop_windows.png')"
									alt="app"
								/>
							</div>
						</div>
					</li>
					<svg
						version="1.1"
						id="레이어_1"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						x="0px"
						y="0px"
						viewBox="0 0 100 100"
						style="enable-background: new 0 0 100 100"
						xml:space="preserve"
					>
						<path
							class="st0"
							d="M69.1,35.8L50,54.9L30.9,35.8L25,41.7l25,25l25-25L69.1,35.8z"
						/>
					</svg>
					<!-- //step loof -->
					<!-- step loof -->
					<li class="roundbox-txt">
						<div class="step-icon">
							<img :src="require('@/assets/img/step_5.png')" alt="step1" />
						</div>
						<div class="step-txt">
							<span>Step <i>05</i></span>
							<p>법인용 우체국 인증서 신청 심사완료</p>
							<em>(법인용 우체국 인증서 발급 승인 완료)</em>
						</div>
						<div class="info-icon">
							<div class="web step">
								<img
									:src="require('@/assets/img/desktop_windows.png')"
									alt="app"
								/>
							</div>
						</div>
					</li>
					<svg
						version="1.1"
						id="레이어_1"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						x="0px"
						y="0px"
						viewBox="0 0 100 100"
						style="enable-background: new 0 0 100 100"
						xml:space="preserve"
					>
						<path
							class="st0"
							d="M69.1,35.8L50,54.9L30.9,35.8L25,41.7l25,25l25-25L69.1,35.8z"
						/>
					</svg>
					<!-- //step loof -->
					<!-- step loof -->
					<li class="roundbox-txt">
						<div class="step-icon">
							<img :src="require('@/assets/img/step_6.png')" alt="step1" />
						</div>
						<div class="step-txt">
							<span>Step <i>06</i></span>
							<p>법인용 우체국 인증서 발급 완료</p>
							<em>(우체국 앱 → 법인용 우체국 인증서 발급 받기)</em>
						</div>
						<div class="info-icon">
							<div class="app step">
								<img
									:src="require('@/assets/img/phone_iphone.png')"
									alt="app"
								/>
							</div>
						</div>
					</li>
					<!-- //step loof -->
				</ul>
			</div>
			<!-- //step 1~6 -->
			<p class="did-txt">
				발급받으신 법인용 우체국 인증서로 우체국 앱에서<br />다양하고 편리한
				서비스를 이용해보세요.
			</p>
		</div>
		<div class="btn-app">
			<a @click="confirm">
				<button type="button" class="btn-select">확인</button>
			</a>
		</div>
	</div>
</template>

<style scoped>
.top-4 {
	padding-top: 4rem;
}
</style>
<script>
export default {
	name: 'RegisterGuideForCorp',

	methods: {
		confirm() {
			this.$router.go(-1);
		},
	},
};
</script>
