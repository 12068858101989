<template>
	<div class="m24 wrapper">
		<!-- Header section -->
		<div class="top-nav">
			<div class="icon-txt">
				<p>접근권한</p>
			</div>
		</div>
		<!-- End of header section -->

		<ul class="box-container top-4">
			<li class="box-txt">
				<div class="icon-database"></div>
				<div class="box-agree">
					<i>저장공간</i>&nbsp;<em>(필수)</em>
					<p>
						앱 구동에 필요한 파일 생성 및 증명서 저장을 위하여 파일 저장소 접근
						권한이 필요합니다.
					</p>
				</div>
				<div class="box-shadow"></div>
			</li>
			<li class="box-txt">
				<div class="icon-camera"></div>
				<div class="box-agree sub">
					<i>카메라</i>&nbsp;<em>(선택)</em>
					<p>QR코드 인식, 구비서류 제출을 위해 카메라 권한이 필요합니다.</p>
				</div>
				<div class="box-shadow"></div>
			</li>
		</ul>

		<div class="btn-app">
			<button type="button" class="btn-select" @click="doRequestPermission">
				확인
			</button>
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { KPIC_dispatchStartPermission } from '@/util/appBridge';

export default {
	name: '',
	methods: {
		...mapActions(['doPostSeriveRegister']),
		...mapMutations(['savePermissionState']),

		doRequestPermission() {
			// 네이티브에 앱 권한 요청을 한다.
			console.debug('register', 'doRequestPermissionCallback({RESULT: "OK"})');
			KPIC_dispatchStartPermission();
			window.doRequestPermissionCallback = this.doRequestPermissionCallback;
		},

		doRequestPermissionCallback(res) {
			window.doRequestPermissionCallback = null;
			if (res.RESULT) {
				if (res.RESULT === 'OK') {
					this.savePermissionState(true);
					this.doPostSeriveRegister();
				}
			}
		},
	},

	beforeDestroy() {
		window.doRequestPermissionCallback = null;
	},
};
</script>

<style scoped>
.top-4 {
	padding-top: 4rem;
}
</style>
